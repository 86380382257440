<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-md-4">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="endDateFormatted"
              label="Дата"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              @blur="endDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ru-RU"
            first-day-of-week="1"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-12 col-md-4">
        <v-text-field
          hide-details
          label="Название"
          v-model="name_filter"
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="col-12 col-md-4">
        <v-btn block @click="create">Новый</v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="reports"
          :loading="reports_loading"
          :options.sync="options"
          item-key="id"
          :items-per-page="25"
          :server-items-length="total_reports"
          height="74vh"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, 100, -1],
          }"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="4"
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadItem(item)"
                  >Скачать<v-icon>mdi-open-in-new</v-icon></v-btn
                >
              </template>
              <span>Открыть в новом окне</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog width="500" v-model="create_dialog"
        ><CreateReport></CreateReport
      ></v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import CreateReport from "@/views/report/CreateReport";

export default {
  name: "Reports",
  components: {
    CreateReport,
  },
  created() {
    this.load_reports();
  },
  data: () => {
    return {
      menu2: false,
      endDateFormatted: null,
      endDate: null,
      name_filter: null,
      headers: [
        {
          text: "Название",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Дата создания",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        { text: "", value: "actions", sortable: false },
      ],
      options: {
        page: 1,
        sortBy: ["id"],
        sortDesc: [false],
      },
    };
  },
  watch: {
    endDate() {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
    params: {
      handler() {
        this.load_reports();
      },
      deep: true,
    },
    options: {
      handler() {
        this.load_reports();
      },
      deep: true,
    },
  },
  computed: {
    create_dialog() {
      return this.$store.getters.CREATE_DIALOG;
    },
    reports() {
      return this.$store.getters.REPORTS;
    },
    reports_loading() {
      return this.$store.getters.LOADING_REPORTS;
    },
    total_reports() {
      return this.$store.getters.TOTAL_REPORTS;
    },
    params() {
      return {
        ...this.options,
        repDate: this.endDate,
        name: this.name_filter,
      };
    },
  },
  methods: {
    load_reports() {
      this.$store.dispatch("list_reports", this.params);
    },
    downloadItem(item) {
      const filename = item.name;
      this.axios
        .get(`reports/${filename}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    create() {
      this.$store.dispatch("open_create_dialog");
    },
  },
};
</script>

<style scoped></style>
