var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","label":"Дата","prepend-icon":"mdi-calendar","readonly":""},on:{"blur":_vm.endDate},model:{value:(_vm.endDateFormatted),callback:function ($$v) {_vm.endDateFormatted=$$v},expression:"endDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"ru-RU","first-day-of-week":"1"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('v-text-field',{attrs:{"hide-details":"","label":"Название","clearable":""},model:{value:(_vm.name_filter),callback:function ($$v) {_vm.name_filter=$$v},expression:"name_filter"}})],1),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.create}},[_vm._v("Новый")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"loading":_vm.reports_loading,"options":_vm.options,"item-key":"id","items-per-page":25,"server-items-length":_vm.total_reports,"height":"74vh","footer-props":{
          'items-per-page-options': [10, 25, 50, 100, -1],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"4","rounded":""},on:{"click":function($event){return _vm.downloadItem(item)}}},'v-btn',attrs,false),on),[_vm._v("Скачать"),_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Открыть в новом окне")])])]}}])})],1)],1),_c('v-row',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.create_dialog),callback:function ($$v) {_vm.create_dialog=$$v},expression:"create_dialog"}},[_c('CreateReport')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }