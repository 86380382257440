<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2"> Новый отчет </v-card-title>
    <v-col cols="12">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            hide-details
            v-model="startDateFormatted"
            label="От"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          locale="ru-RU"
          first-day-of-week="1"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12">
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            hide-details
            v-model="endDateFormatted"
            label="До"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="endDate"
          locale="ru-RU"
          first-day-of-week="1"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-btn block color="primary" @click="create">Создать</v-btn>
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "create",
  data() {
    return {
      menu: false,
      menu2: false,
      startDate: null,
      endDate: null,
      startDateFormatted: null,
      endDateFormatted: null,
    };
  },
  watch: {
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
    },
    endDate() {
      if (this.endDate < this.startDate) {
        this.endDate = this.startDate;
      }
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    create() {
      if (this.startDate == null || this.endDate == null) {
        return;
      }

      this.$store.dispatch("create_report", {
        date_from: this.startDate,
        date_to: this.endDate,
      });
    },
  },
};
</script>

<style scoped></style>
